import { useEffect, useState, lazy, Suspense } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ScrollToTop from './components/scrollToTop/ScrollToTop'
import { MainContext } from './MainContext'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  Citizen,
  CitizenInput,
  CitizenResponse,
  CITIZEN_QUERY,
} from './operations/queries/getCitizen'
import i18n from './i18n'
import NoCitizen from './components/noCitizen'
import moment from 'moment-timezone'
import 'moment/locale/fo'
import 'moment/locale/da'
import 'moment/locale/de'
import LoadingIndicator from './components/loadingIndicator'
import {
  GetUserInput,
  GetUserResponse,
  GET_USER,
} from './operations/queries/getUser'
import {
  SubscriptionCitizen,
  useSubscribeToCitizen,
} from './operations/subscriptions/getCitizen'
import Header from './components/header/Header'
import { HeaderContainer } from './styles/global'
import { setAuthenticatedUser } from './utils/applicationinsights'

const NotificationOverview = lazy(
  () => import('./components/notificationOverview')
)
const Settings = lazy(() => import('./components/settings/Settings'))
const Login = lazy(() => import('./components/login'))

const App = () => {
  const { isAuthenticated, user, isLoading } = useAuth0()

  if (isLoading) return <LoadingIndicator fullscreen />

  if (!isAuthenticated || !user) {
    return (
      <Suspense fallback={null}>
        <Login />
      </Suspense>
    )
  }
  setAuthenticatedUser(
    user['https://hasura.io/jwt/claims']?.['x-hasura-ta-user-id'] ||
      'no ta-user-id in auth0 authorisation'
  )

  return <HasCitizen user={user}></HasCitizen>
}

const HasCitizen = ({ user }: any) => {
  const { t } = useTranslation()
  const citizenQuery = useQuery<CitizenResponse, CitizenInput>(CITIZEN_QUERY, {
    variables: {
      email: user.email,
    },
  })

  const userQuery = useQuery<GetUserResponse, GetUserInput>(GET_USER, {
    variables: { email: user.email },
  })

  const currentUser = userQuery.data?.ta_user[0]

  useEffect(() => {
    const fixupDanish = (userLanguage: string) =>
      userLanguage === 'dk' ? 'da' : userLanguage

    const userLanguage = fixupDanish(
      currentUser?.user_language || i18n.language || 'en'
    )
    i18n.changeLanguage(userLanguage)
  }, [currentUser])

  useEffect(() => {
    moment.locale(t(`moment`))
  }, [t])

  if (citizenQuery.loading || userQuery.loading)
    return <LoadingIndicator fullscreen />

  if (citizenQuery.error || userQuery.error) {
    return <p>{t('general.error')}</p>
  }

  if (!currentUser) {
    return null
  }

  if (
    citizenQuery.data?.ta_citizen &&
    citizenQuery.data?.ta_citizen.length > 0
  ) {
    return (
      <Content
        citizens={citizenQuery.data.ta_citizen}
        currentUser={currentUser}
      ></Content>
    )
  }
  return (
    <NoCitizen
      refetchCitizen={citizenQuery.refetch}
      refetchUser={userQuery.refetch}
    />
  )
}

interface ContentProps {
  citizens: Citizen[]
  currentUser: Api.User
}

const Content = ({ citizens, currentUser }: ContentProps) => {
  const [citizenId, setCitizenId] = useState(
    localStorage.getItem('currentCitizen')
  )

  const citizen = citizens.find((c: any) => c.id === citizenId) || citizens[0]

  const citizenSubscription = useSubscribeToCitizen({ id: citizen.id })

  const offlineGateways = getOfflineGateways(
    citizenSubscription.data?.ta_citizen || []
  )

  return (
    <MainContext.Provider
      value={{
        currentCitizen: citizen,
        availableCitizens: citizens,
        setCitizenId: setCitizenId,
        offlineGateways,
        currentUser,
      }}
    >
      <Router>
        <HeaderContainer>
          <Header />
        </HeaderContainer>

        <Suspense fallback={null}>
          <Routes>
            <Route path="/callback" element={<NotificationOverview />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/" element={<NotificationOverview />} />
          </Routes>
        </Suspense>
        <ScrollToTop />
      </Router>
    </MainContext.Provider>
  )
}

function getOfflineGateways(citizen: SubscriptionCitizen[]) {
  var initialValue = ''
  citizen?.forEach((currentCit) => {
    currentCit.ta_citizen_gateways?.forEach((current) => {
      if (!current.ta_gateway.online) {
        if (initialValue.length > 0) {
          initialValue += ', '
        }

        initialValue += current.ta_gateway.serial
      }
    })
  })

  return initialValue
}

export default App
